<template>
  <div class="nav">
    <div class="container">
      <div class="nav__flex">
        <div class="nav__left">
          <router-link to="/" tag="a">
            <img
              src="@/assets/logo2.png"
              class="logo"
              alt="logo"
              width="102px"
              height="50px"
            />
          </router-link>
          <div
            id="burger"
            :class="{ active: isBurgerActive }"
            @click.prevent="toggleMenu"
          >
            <slot>
              <button type="button" class="burger-button" title="Menu">
                <span class="burger-bar burger-bar--1"></span>
                <span class="burger-bar burger-bar--2"></span>
                <span class="burger-bar burger-bar--3"></span>
              </button>
            </slot>
          </div>
        </div>
        <div class="nav__menu" :class="{ nav_active: isBurgerActive }">
          <ul class="nav__links">
            <router-link to="/" tag="li" active-class="active" exact>
              <a>{{ $t("nav.main") }}</a>
            </router-link>
            <router-link to="/about" tag="li" active-class="active" exact>
              <a>{{ $t("nav.about") }}</a>
            </router-link>
            <router-link to="/schools" tag="li" active-class="active" exact>
              <a>{{ $t("nav.schools") }}</a>
            </router-link>
            <router-link
              to="/publish-house"
              tag="li"
              active-class="active"
              exact
            >
              <a>{{ $t("nav.publishHouse") }}</a>
            </router-link>
            <router-link to="/nil" tag="li" active-class="active" exact>
              <a>{{ $t("nav.nil") }}</a>
            </router-link>
          </ul>
          <div class="nav__flex">
            <select
              name="selectLang"
              id="lang"
              class="lang"
              @change="switchLocale($event.target.value)"
            >
              <option v-for="locale in locales" :key="locale" :value="locale">
                {{ locale }}
              </option>
            </select>
            <div class="vl"></div>
            <div class="nav__social">
              <a
                href="https://www.instagram.com/shon.qz/?utm_medium=copy_link"
                target="_blank"
                ><div class="icon-insta"></div
              ></a>
              <a href="mailto: info@shon.kz" target="_blank"
                ><div class="icon-mail"></div
              ></a>
              <a href="tel: +7 (707) 677 78 55" target="_blank"
                ><div class="icon-phone"></div
              ></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      locales: process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(","),
      isBurgerActive: false,
    };
  },
  created() {
    this.getNews();
    this.getSmi();
  },
  methods: {
    ...mapActions(["getNews", "getSmi"]),
    toggleMenu() {
      this.isBurgerActive = !this.isBurgerActive;
    },
    async switchLocale(locale) {
      if (this.$i18n.locale !== locale) {
        this.$i18n.locale = locale;
        await this.getNews();
        await this.getSmi();
      }
    },
  },
  watch: {
    $route(to, from) {
      this.isBurgerActive = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.icon-insta {
  width: 19px;
  height: 19px;
  background-image: url("../assets/icons/insta.svg");
  background-repeat: no-repeat;
  background-position: center;
}
.icon-phone {
  width: 19px;
  height: 19px;
  background-image: url("../assets/icons/phone.svg");
  background-repeat: no-repeat;
  background-position: center;
}
.icon-mail {
  width: 26px;
  height: 19px;
  background-image: url("../assets/icons/mail.svg");
  background-repeat: no-repeat;
  background-position: center;
  margin: 0 14px;
}
.burger-button {
  display: none;
}
.lang {
  margin-right: 19px;
  border: none;
  outline: none;
  text-transform: uppercase;
  background: none;
  cursor: pointer;
  font-size: 15px;
  line-height: 23px;
  color: #ffffff;
  text-transform: uppercase;
  transition: 0.3s ease-out;
  &:hover {
    opacity: 0.7;
  }
}
.vl {
  height: 68px;
  border-left: 2px solid #e66e00;
}
.nav {
  max-height: 119px;
  padding: 20px 0;
  background: #00303a;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
  &__social {
    display: flex;
    align-items: center;
    margin-left: 15px;
  }
  &__menu {
    display: flex;
    align-items: center;
    margin-left: 50px;
    width: 100%;
    justify-content: space-between;
  }
  &__left {
    display: flex;
    align-items: center;
  }
  &__flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__links {
    display: flex;
    align-items: center;
    list-style-type: none;
    li {
      margin-right: 25px;
    }
    a {
      font-weight: 400;
      font-size: 17px;
      line-height: 20px;
      color: #ffffff;
      text-decoration: none;
      transition: 0.3s ease-out;
      &:hover {
        color: #e66e00;
      }
    }
  }
}
.active a {
  color: #e66e00;
}
@media (max-width: 1200px) {
  .vl {
    display: none;
  }
  .burger-button {
    position: relative;
    outline: 0;
    cursor: pointer;
    height: 30px;
    width: 32px;
    display: block;
    z-index: 999;
    border: 0;
    border-radius: 0;
    background-color: transparent;
    pointer-events: all;
    transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  }

  .burger-bar {
    background-color: #e66e00;
    position: absolute;
    top: 50%;
    right: 6px;
    left: 6px;
    height: 2px;
    width: auto;
    margin-top: -1px;
    transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1),
      opacity 0.3s cubic-bezier(0.165, 0.84, 0.44, 1),
      background-color 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  }

  .burger-bar--1 {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
  }

  .burger-bar--2 {
    transform-origin: 100% 50%;
    transform: scaleX(0.8);
  }

  .burger-button:hover .burger-bar--2 {
    transform: scaleX(1);
  }

  .no-touchevents .burger-bar--2:hover {
    transform: scaleX(1);
  }

  .burger-bar--3 {
    transform: translateY(6px);
  }
  #burger {
    width: 50px;
  }
  #burger.active .burger-button {
    transform: rotate(-180deg);
  }

  #burger.active .burger-bar {
    background-color: #ffffff;
  }

  #burger.active .burger-bar--1 {
    transform: rotate(45deg);
  }

  #burger.active .burger-bar--2 {
    opacity: 0;
  }

  #burger.active .burger-bar--3 {
    transform: rotate(-45deg);
  }
  .lang {
    margin: 0 0 20px 0;
  }
  .nav {
    max-height: none;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 9999;
    &__flex {
      flex-direction: column;
    }
    &__left {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }
    &__menu {
      flex-direction: column;
      width: 100%;
      max-height: 0;
      overflow: hidden;
      transition: 0.3s ease-in;
    }
    &__links {
      flex-direction: column;
      margin: 30px 0;
      width: 100%;
      li {
        margin-right: 0;
        width: 100%;
        text-align: center;
        a {
          width: 100%;
          display: block;
          font-size: 16px;
        }
        &:not(:last-of-type) {
          margin-bottom: 30px;
        }
      }
    }
  }
  .nav_active {
    max-height: 400px;
  }
}
</style>
