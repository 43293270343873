import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import i18n from "../i18n";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    news: null,
    smi: null,
  },
  getters: {
    news: (state) => state.news,
    smi: (state) => state.smi,
  },
  mutations: {
    getNews(state, value) {
      state.news = value;
    },
    getSmi(state, value) {
      state.smi = value;
    },
  },
  actions: {
    async getNews({ commit }) {
      await axios
        .get(`/data/news/news-${i18n.locale}.json`)
        .then((res) => {
          commit("getNews", res.data.news);
        })
        .catch((err) => console.error(err));
    },
    async getSmi({ commit }) {
      await axios
        .get(`/data/smi/smi-${i18n.locale}.json`)
        .then((res) => {
          commit("getSmi", res.data.smi);
        })
        .catch((err) => console.error(err));
    },
  },
});
