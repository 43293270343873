import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: "Главная | SHON",
    },
  },
  {
    path: "/about",
    name: "About",
    meta: {
      title: "О нас  | SHON",
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/schools",
    name: "Schools",
    meta: {
      title: "Школы  | SHON",
    },
    component: () =>
      import(/* webpackChunkName: "schools" */ "../views/Schools.vue"),
  },
  {
    path: "/recipes-of-my-identity",
    name: "Recipes",
    meta: {
      title: "Конкурс «Рецепты моей идентичности»  | SHON",
    },
    component: () =>
      import(/* webpackChunkName: "recipes" */ "../views/Recipes.vue"),
  },
  {
    path: "/publish-house",
    name: "PublishHouse",
    meta: {
      title: "Shon Publishing House | SHON",
    },
    component: () =>
      import(
        /* webpackChunkName: "publishHouse" */ "../views/PublishHouse.vue"
      ),
  },
  {
    path: "/nil",
    name: "NIL",
    meta: {
      title: "Научно-исследовательская лаборатория (НИЛ) | SHON",
    },
    component: () =>
      import(/* webpackChunkName: "nil" */ "../views/NilLab.vue"),
  },
  {
    path: "/news/:slug",
    name: "News",
    meta: {
      title: "Новости| SHON",
    },
    component: () => import(/* webpackChunkName: "news" */ "../views/News.vue"),
  },
  {
    path: "/404",
    name: "404",
    meta: {
      title: "Страница не найдена | SHON",
    },
    component: () =>
      import(/* webpackChunkName: "notfound" */ "../views/NotFound.vue"),
  },
  {
    path: "*",
    redirect: "/404",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || "Shon KZ";
  next();
});

export default router;
