<template>
  <div>
    <div class="main_banner">
      <vue-particles
        color="#FFFFFF"
        :particlesNumber="80"
        shapeType="circle"
        :particleSize="6"
        linesColor="#FFFFFF"
        :linesWidth="1"
        :lineLinked="true"
        :lineOpacity="0.8"
        :linesDistance="150"
        :moveSpeed="3"
        :hoverEffect="true"
        hoverMode="grab"
        :clickEffect="true"
        clickMode="push"
      >
      </vue-particles>
      <div class="main_heading">
        <div class="main_box">
          <span>{{ $t("main.bannerDesc") }}</span>
          <h1>{{ $t("main.bannerTitle") }}</h1>
          <router-link
            to="/recipes-of-my-identity"
            tag="button"
            class="btn_orange"
            >{{ $t("main.bannerBtn") }}</router-link
          >
        </div>
      </div>
    </div>
    <div class="about">
      <div class="container">
        <div class="about__banner">
          <h2 class="about__title">
            {{ $t("main.aboutTitle") }}
          </h2>
          <div class="about__body">
            <p v-html="$t('main.aboutDesc')"></p>
          </div>
        </div>
      </div>
    </div>
    <div class="smi">
      <div class="container">
        <h2 class="section_title">
          {{ $t("main.smiTitle") }}
          <div class="arrows">
            <div class="icon-arrow-left" @click="showPrevSmi"></div>
            <div class="icon-arrow-right" @click="showNextSmi"></div>
          </div>
        </h2>
        <div class="smi__carousel" v-if="smi && isReady">
          <VueSlickCarousel v-bind="settings1" ref="smi">
            <div class="cl-item" v-for="(s, i) in smi" :key="i">
              <a
                class="smi-item"
                :href="s.urlLink"
                target="_blank"
                :style="{
                  'background-image':
                    'linear-gradient( rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4) ),url(' +
                    s.imgLink +
                    ')',
                }"
              >
                <div class="smi-info">
                  {{ s.title }}
                </div>
              </a>
            </div>
          </VueSlickCarousel>
        </div>
      </div>
    </div>
    <div class="partners">
      <div class="container">
        <h2 class="section_title">{{ $t("main.partnersTitle") }}</h2>
        <div class="partners__list">
          <img
            :src="require('@/assets/images/partner-1.png')"
            class="partner"
            alt="partner"
          />
          <img
            :src="require('@/assets/images/partner-2.png')"
            class="partner"
            alt="partner"
          />
          <img
            :src="require('@/assets/images/partner-3.png')"
            class="partner"
            alt="partner"
          />
          <img
            :src="require('@/assets/images/partner-4.png')"
            class="partner"
            alt="partner"
          />
          <img
            :src="require('@/assets/images/partner-5.png')"
            class="partner"
            alt="partner"
          />
          <img
            :src="require('@/assets/images/partner-6.png')"
            class="partner"
            alt="partner"
          />
          <img
            :src="require('@/assets/images/partner-7.png')"
            class="partner"
            alt="partner"
          />
          <img
            :src="require('@/assets/images/partner-8.png')"
            class="partner"
            alt="partner"
          />
          <img
            :src="require('@/assets/images/partner-9.png')"
            class="partner"
            alt="partner"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  name: "Home",
  data() {
    return {
      isReady: false,
      settings: {
        slidesToShow: 3,
        infinite: true,
        arrows: false,
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 1,
              infinite: true,
            },
          },
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      },
      settings1: {
        slidesToShow: 3,
        infinite: true,
        arrows: false,
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 1,
              infinite: true,
            },
          },
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters(["news", "smi"]),
  },
  methods: {
    showPrevNews() {
      this.$refs.news.prev();
    },
    showNextNews() {
      this.$refs.news.next();
    },
    showPrevSmi() {
      this.$refs.smi.prev();
    },
    showNextSmi() {
      this.$refs.smi.next();
    },
  },
  created() {
    if (this.news && this.news.length < 3) {
      if (this.news.length === 2) {
        this.settings.slidesToShow = 2;
      } else {
        this.settings.slidesToShow = 1;
      }
    } else {
      this.settings.slidesToShow = 3;
    }
    if (this.smi && this.smi.length < 3) {
      if (this.smi.length === 2) {
        this.settings1.slidesToShow = 2;
      } else {
        this.settings1.slidesToShow = 1;
      }
    } else {
      this.settings1.slidesToShow = 3;
    }
    this.isReady = true;
  },
  components: { VueSlickCarousel },
};
</script>

<style skoped lang="scss">
.news-info {
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
}
.smi-info {
  color: #f0f0f0;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  text-decoration-line: underline;
}
.calendar-icon {
  width: 15.6px;
  height: 12.9px;
  background-image: url("../assets/icons/calendar.svg");
  background-repeat: no-repeat;
  background-position: center;
  margin-right: 5px;
}
.news-date {
  margin-bottom: 3px;
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 14px;
}
.cl-item {
  padding-right: 20px;
}
.smi-item {
  cursor: pointer;
  width: 367px;
  height: 188px;
  margin: 0 auto;
  color: #ffffff;
  padding: 30px;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: flex-end;
  background-position: center;
}
.news-item {
  cursor: pointer;
  width: 367px;
  margin: 0 auto;
  height: 229px;
  color: #ffffff;
  padding: 30px;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: flex-end;
  background-position: center;
}
.partner {
  width: 105px;
  height: 105px;
  margin: 5px;
}
.partners {
  padding: 34px 0 54px 0;
  background-color: #ffffff;
  &__list {
    justify-content: space-between;
    display: flex;
    align-items: center;
    margin-top: 20px;
    flex-wrap: wrap;
  }
}
.smi {
  padding: 34px 0 54px 0;
  &__carousel {
    margin-top: 20px;
  }
}
.news {
  background-color: #ffffff;
  padding: 28px 0 70px 0;
  &__carousel {
    margin-top: 20px;
  }
}
.arrows {
  display: flex;
  align-items: center;
}
.icon-arrow-left {
  cursor: pointer;
  margin-right: 10px;
  width: 27px;
  height: 26px;
  background-image: url("../assets/icons/arrow-left.svg");
  background-repeat: no-repeat;
  background-position: center;
  transition: 0.3s ease-out;
  &:hover {
    transform: scale(0.95);
  }
}
.icon-arrow-right {
  cursor: pointer;
  width: 27px;
  height: 26px;
  background-image: url("../assets/icons/arrow-right.svg");
  background-repeat: no-repeat;
  background-position: center;
  transition: 0.3s ease-out;
  &:hover {
    transform: scale(0.95);
  }
}
.item-1 {
  background-image: url("../assets/images/project1.png");
  height: 280px;
}
.item-2 {
  background-image: url("../assets/images/project2.png");
  height: 280px;
}
.item-3 {
  background-image: url("../assets/images/project3.png");
  height: 466px;
}
.item-4 {
  background-image: url("../assets/images/project4.png");
  height: 466px;
}
.section_title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 35px;
  line-height: 41px;
  text-transform: uppercase;
  padding-bottom: 15px;
  border-bottom: 2px solid #e66e00;
  color: #202020;
}
.projects {
  margin: 50px 0;
  &__list {
    display: flex;
    margin-top: 36px;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
  }
  &__item {
    max-width: 560px;
    border-radius: 5px;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    padding: 30px;
  }
  &__info {
    color: #ffffff;
    align-self: flex-end;
    h3 {
      font-weight: 500;
      font-size: 30px;
      line-height: 35px;
    }
    p {
      font-size: 18px;
      line-height: 21px;
      font-weight: 300;
      margin-top: 20px;
    }
  }
}
.about {
  background-color: #ffffff;
  height: 650px;
  &__banner {
    background-size: contain;
    background-image: url("/data/news/images/3.jpg");
    background-repeat: no-repeat;
    background-position: left;
    height: 555px;
    text-align: right;
    padding-top: 38px;
  }
  &__title {
    font-size: 35px;
    line-height: 41px;
    color: #202020;
    padding-bottom: 18px;
    border-bottom: 2px solid #202020;
    max-width: 631px;
    margin-left: auto;
  }
  &__body {
    padding: 47px 50px 33px 42px;
    font-size: 20px;
    text-align: left;
    background: #00303a;
    border-radius: 2px;
    font-weight: 300;
    line-height: 23px;
    color: #ffffff;
    position: relative;
    margin-top: 50px;
    max-width: 535px;
    margin-left: auto;
    &:before {
      content: "";
      display: block;
      width: 389px;
      height: 30px;
      background: #e66e00;
      border-radius: 2px;
      right: 0;
      top: -20px;
      position: absolute;
    }
  }
}

.btn_orange {
  padding: 12px 0;
  font-size: 10px;
  line-height: 13px;
  cursor: pointer;
  border: none;
  color: #ffffff;
  background: #e66e00;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  width: 147px;
  font-weight: bold;
  font-size: 11px;
  line-height: 13px;
  transition: 0.2s ease-out;
  &:hover {
    transform: scale(0.96);
  }
}
.main_heading {
  position: absolute;
  top: 50%;
  left: 35%;
  transform: translate(-50%, -50%);
}
.main_box {
  padding: 38px 25px;
  color: #ffffff;
  background: #01303a;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.4);
  border-radius: 2px;
  span {
    font-size: 20px;
    line-height: 23px;
  }
  h1 {
    margin: 19px 0;
    font-size: 32px;
    line-height: 38px;
  }
}
.home {
  width: 100%;
}
.main_banner {
  height: 601px;
  overflow: hidden;
  position: relative;
  width: 100%;
  background-size: cover;
  background-image: url("../assets/images/main_banner.png");
  background-repeat: no-repeat;
  background-position: center;
}
@media (max-width: 768px) {
  .cl-item {
    padding: 0;
  }
  .news-info,
  .smi-info {
    font-size: 15px;
  }
  .projects__info {
    h3 {
      font-size: 18px;
      line-height: 18px;
    }
    p {
      font-size: 14px;
      line-height: 15px;
      margin-top: 10px;
    }
  }
  .section_title {
    font-size: 18px;
    line-height: 18px;
  }
  .about {
    height: 400px;
    &__title {
      font-size: 15px;
      padding-top: 25px;
      line-height: 18px;
      padding-bottom: 0px;
      border-bottom: 1px solid #202020;
      width: 151px;
    }
    &__banner {
      padding-top: 0;
      height: 220px;
    }
    &__body {
      margin: 170px 0 0 0;
      font-size: 12px;
      line-height: 15px;
      width: 95%;
      padding: 20px;
      &::before {
        height: 15px;
        right: -20px;
        top: -10px;
        width: 268px;
      }
    }
  }
}
@media (max-width: 480px) {
  .about {
    height: 400px;
    &__title {
      font-size: 15px;
      padding-top: 25px;
      line-height: 18px;
      padding-bottom: 0px;
      border-bottom: 1px solid #202020;
      width: 151px;
    }
    &__banner {
      padding-top: 0;
      height: 220px;
    }
    &__body {
      margin: 170px 0 0 0;
      font-size: 12px;
      line-height: 15px;
      width: 95%;
      padding: 20px;
      &::before {
        height: 15px;
        right: -20px;
        top: -10px;
        width: 268px;
      }
    }
  }
  .news-item,
  .smi-item {
    width: 100%;
    padding: 30px 30px 30px 40px;
  }
  .btn_orange {
    font-size: 10px;
  }
  .main_box {
    padding: 20px;
    span {
      font-size: 15px;
    }
    h1 {
      font-size: 17.0886px;
      margin: 10 0px;
    }
  }
  .main_heading {
    width: 90%;
    left: 50%;
  }
}
</style>
