<template>
  <footer class="footer">
    <div class="container">
      <div class="footer__flex">
        <div class="footer__info">
          <a
            href="https://2gis.kz/almaty/firm/9429940000791249/tab/info?m=76.931491%2C43.228256%2F16"
            target="_blank"
            class="footer__contact"
          >
            <div class="icon-location icon"></div>
            {{ $t("footer.address") }}
          </a>
          <a href="tel: +7 (707) 677 78 55" class="footer__contact">
            <div class="icon-phone icon"></div>
            +7 (707)-6-777-855
          </a>
          <a href="mailto: info@shon.kz" class="footer__contact">
            <div class="icon-mail icon"></div>
            info@shon.kz
          </a>
          <div class="footer__social">
            <a
              href="https://www.instagram.com/shon.qz/?utm_medium=copy_link"
              target="_blank"
            >
              <div class="icon icon-instagram"></div>
            </a>
            <a
              href="https://www.facebook.com/profile.php?id=100071240577189"
              target="_blank"
            >
              <div class="icon icon-facebook"></div>
            </a>
            <a href="/" target="_blank">
              <div class="icon icon-twitter"></div>
            </a>
            <a href="/" target="_blank">
              <div class="icon icon-telegram"></div>
            </a>
          </div>
        </div>
        <div class="vl"></div>
        <div class="footer__location">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2907.1185627690966!2d76.9302762153939!3d43.22797377913817!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38836f26f89f2efd%3A0x4bc3c206ad224129!2sMarkov%20St%2047%2C%20Almaty%20050040!5e0!3m2!1sen!2skz!4v1639229624083!5m2!1sen!2skz"
            width="100%"
            height="317"
            style="border: 0"
            allowfullscreen=""
            loading="lazy"
          ></iframe>
        </div>
      </div>
      <div class="footer__nav">
        <a href="/"
          ><img
            :src="require('@/assets/logo2.png')"
            alt="logo"
            width="102px"
            height="50px"
        /></a>
        <ul class="footer__links">
          <li>
            <router-link to="/about" tag="a">
              {{ $t("nav.about") }}
            </router-link>
          </li>
          <li>
            <router-link to="/schools" tag="a">
              {{ $t("nav.schools") }}
            </router-link>
          </li>
          <li>
            <router-link to="/publish-house" tag="a">
              {{ $t("nav.publishHouse") }}
            </router-link>
          </li>
          <li>
            <router-link to="/nil" tag="a">
              {{ $t("nav.nil") }}
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </footer>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
.icon {
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  flex-shrink: 0;
  background-position: center;
  margin-right: 20px;
  transition: 0.3s ease-out;
  &:hover {
    opacity: 0.6;
  }
}
.icon-location {
  background-image: url("../assets/icons/location.svg");
}
.icon-phone {
  background-image: url("../assets/icons/phone-footer.svg");
}
.icon-mail {
  background-image: url("../assets/icons/mail-footer.svg");
}
.icon-instagram {
  background-image: url("../assets/icons/insta-footer.svg");
}
.icon-facebook {
  background-image: url("../assets/icons/facebook-footer.svg");
}
.icon-twitter {
  background-image: url("../assets/icons/twitter-footer.svg");
}
.icon-telegram {
  background-image: url("../assets/icons/telegram-footer.svg");
}
.vl {
  border-left: 2px solid #ffffff;
  height: 350px;
  margin-right: 84px;
}
.footer {
  background-color: #00303a;
  position: absolute;
  padding: 26px 0;
  bottom: 0;
  right: 0;
  left: 0;
  &__nav {
    padding: 38px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__links {
    list-style: none;
    display: flex;
    align-items: center;
    a {
      font-size: 17px;
      text-decoration: none;
      line-height: 20px;
      color: #ffffff;
      transition: 0.3s;
      &:hover {
        color: #e66e00;
      }
    }
    li {
      margin-left: 30px;
    }
  }
  &__flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 26px;
    border-bottom: 2px solid #ffffff;
  }
  &__location {
    width: 50%;
  }
  &__info {
    width: 50%;
  }
  &__contact {
    display: flex;
    max-width: 300px;
    font-size: 17px;
    margin-top: 12px;
    line-height: 20px;
    color: #ffffff;
    text-decoration: none;
    font-weight: 300;
  }
  &__social {
    display: flex;
    margin-top: 25px;
    align-items: center;
  }
}
@media (max-width: 1200px) {
  .vl {
    display: none;
  }
  .footer {
    &__flex {
      flex-direction: column;
    }
    &__location {
      width: 100%;
      margin-top: 15px;
    }
    &__social {
      border-bottom: 2px solid #ffffff;
      padding-bottom: 17px;
    }
    &__info {
      width: 100%;
    }
    &__contact {
      font-size: 12px;
      line-height: 16px;
    }
    &__links {
      li {
        margin-left: 13px;
      }
      a {
        font-size: 9px;
      }
    }
  }
}
</style>
